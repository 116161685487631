

window.WebFontConfig = {
  google: { families: ['Noto+Sans+JP'] },
  active: function() {
    sessionStorage.fonts = true;
  }
};

(function() {
  var wf = document.createElement('script');
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
  wf.type = 'text/javascript';
  wf.async = 'true';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wf, s);
})();



$(window).on("load", function () {
  $('body').removeClass('top');
  deSVG('.svg', true);
  visual_height_fix();
  $("main").fadeIn(0);
  $("header").fadeIn(0);
  $("footer").fadeIn(0);
  


  if (_ua.Mobile) {
  } else {
  }




 
 

  deSVG('.desvg', true);
  var title = $(".about_title .company");
  var title2 = $(".about_title .mainen");
  var title3 = $(".about_title h1");
});

$(function () {
  if (_ua.Mobile) {
    sp_menu();
  } else {
    pc_menu();
  }

  if (_ua.Mobile){
    movie_play_sp();
    head_fixed_margin_sp();
  }else{
    movie_play();
    head_fixed_margin();
/* 
    $(window).scroll(function () {
      if($(window).scrollTop() > 20) {
        $('header').addClass('fixed');
      } else {
        $('header').removeClass('fixed');
      }
    });
    */
  }

  inview();
  ipad_view();
 
  pagetop();
  coming();
  smooth();
  faq();
  career();
  //blankdwindow();

});



function faq() {

  $(".faq_title").on("click",function () {
      $(this).toggleClass('isactive');
      $(this).next(".faq_answer").stop().slideToggle();
      $(this).parent(".faq_box").toggleClass('active');
  });
  $(".faq-button").on("click",function () {
      $(this).prev(".faq-text").stop().slideToggle();
      $(this).parent(".faq-wrapper").toggleClass('active');
  });

}


$(document).on('click','.lity-close',function(){

  var video = $('#video').get(0);
          video.pause();            // video 一時停止
          video.currentTime = 0;    // 次回再生時に最初から再生するなら再生時間を０に
  });
  $(document).on('click', '.lity-wrap',function(){

 var video = $('#video').get(0);
          video.pause();            // video 一時停止
          video.currentTime = 0;    // 次回再生時に最初から再生するなら再生時間を０に
 });

 function movie_play_sp(){
   var video = document.querySelector('#video');
   var video2 = document.querySelector('#video2');
 var video_btn = document.querySelector('#video-btn');
 var is_playing = false;
 var is_playing2 = false;

 $("#video-btn").on("click",function(){
   if (!is_playing) {
     video.play();

     is_playing = true;

   } else {
     video.pause();
     is_playing = false;
;
   }
 })


 $(".video-btn2").on("click",function(){
   if (!is_playing2) {
     video2.play();

     is_playing2 = true;

   } else {
     video2.pause();
     is_playing2 = false;
;
   }
 })


 }

function movie_play(){
var video = document.querySelector('#video');
var video2 = document.querySelector('#video2');
var video_btn = document.querySelector('#video-btn');
var is_playing = false;
var is_playing2 = false;

$("#video-btn").on("click",function(){
 if (!is_playing) {
   video.play();
   video.volume = 0.5;
   is_playing = true;
   $(".video-btn").addClass("active");
   $(".play_btn").addClass("active");
   $("#movie").addClass("active");
 } else {
   video.pause();
   is_playing = false;
   $(".video-btn").removeClass("active");
   $(".play_btn").removeClass("active");
   $("#movie").removeClass("active");
 }
});

$(".video-btn2").on("click",function(){
 if (!is_playing2) {
   video2.play();
   video2.volume = 0.5;
   is_playing2 = true;
   $(".video-btn2").addClass("active");
   $(".play_btn2").addClass("active");
   $("#movie.m2").addClass("active");
 } else {
   video2.pause();
   is_playing2 = false;
   $(".video-btn2").removeClass("active");
   $(".play_btn2").removeClass("active");
   $("#movie.m2").removeClass("active");
 }
})

}


function career(){
  var t = $(".year_title");
  var c = $(".caree_main_cont");

  t.click(function(event){
    $(this).next("div").slideToggle();
    $(this).toggleClass("active");
  })
}

function inview() {
  $(".inview").on("inview", function (event, visible, topOrBottomOrBoth) {
    if (visible) {
      $(this).addClass("active");
    } else {
    }
  });
}



function pc_menu() {
  var hpdd = $(".header_inner").outerHeight();
  $("main.undercont").css("margin-top",hpdd);

  $(".link_btn").on("click", function () {
    event.preventDefault();
    $(this).toggleClass("active");
    $(this).next(".morelink").slideToggle();
  });

  $("#gnav_pc ul li.mainli").not('.onlymenu').on("mouseenter", function () {
    var hpd = $("header").outerHeight();
    console.log(hpd);
    $(".overlay").addClass("active");
    $(this).find(".mega_block").addClass("active");
    $(this).addClass("active");
    $(this).siblings().removeClass("active");
    $(this).find(".megamenu_block").addClass("is-open");
    $(this).find(".megamenu_block").siblings().removeClass("is-open");
    $(this).find(".megamenu_block").css("top",hpd);
    
  });
  $("#gnav_pc ul li.mainli").not('.onlymenu').on("mouseleave", function () {
    $(".overlay").removeClass("active");
    $(this).find(".mega_block").removeClass("active");
    $(this).removeClass("active");
    $(this).find(".megamenu_block").removeClass("is-open");
  });

  $("#spmenu").on("click", function () {
    $(this).toggleClass("active");
    $("#gnav").toggleClass("is-open");
    $("#gnav_pc").toggleClass("is-hopen");
    $("body").toggleClass("is-fixed");
    $("#logomark").toggleClass("white");
    $("header").toggleClass("isopen");
    $("#logomark_normal").toggleClass("white");
    $("p.logotxt").toggleClass("is-active");
  });

  var state = false;

  $(".site_menu").on("click", function () {
    $(this).toggleClass("is-open");
    $("#gnav").toggleClass("is-open");
    $("#gnav_pc").toggleClass("is-hopen");
  });

 

}

var bodyElm = $("body");
var scrollPosi;
function bodyFix() {
  scrollPosi = $(window).scrollTop();
  bodyElm.css({
    position: "fixed",
    width: "100%",
    "z-index": "1",
    top: -scrollPosi,
  });
}

function visual_height_fix(){
  var nH = $('header').innerHeight();// ナビの高さを取得
  var wH = $(window).height();// 表示画面の高さを取得
  var hH = wH - nH;// 表示画面とナビエリアの差分を算出

  //$('#hero').css('min-height', hH + 'px');

}
function head_fixed_margin() {
  var hh = $("header").outerHeight();
  $("body").not(".top").css({ "margin-top": hh + "px" });
}

function head_fixed_margin_sp() {
  var hh = $("header").innerHeight();
  $("body").css({ "margin-top": hh + "px" });


}


function blankdwindow(){
  function ready(fn) {
    if (document.readyState != 'loading') {
      fn();
    } else if (document.addEventListener) {
      document.addEventListener('DOMContentLoaded', fn);
    } else {
      document.attachEvent('onreadystatechange', function() {
        if (document.readyState != 'loading')
          fn();
      });
    }
  }
  ready(function() {
    var website = window.location.hostnam;
    var internalLinkRegex = new RegExp('^((((http:\\/\\/|https:\\/\\/)(www\\.)?)?'
    //+ website
    + ')|(localhost:\\d{4})|(\\/.*))(\\/.*)?$', '');
  var anchorEls = document.querySelectorAll('a');
    var anchorElsLength = anchorEls.length;
  
  for (var i = 0; i < anchorElsLength; i++) {
    var anchorEl = anchorEls[i];
    var href = anchorEl.getAttribute('href');
  if (!internalLinkRegex.test(href)) {
    anchorEl.setAttribute('target', '_blank');
  anchorEl.setAttribute('rel', 'noopener nofollow');
      }
    }
  });
}

function coming() {
  $(".coming a").on("click", function (event) {
    return false;
    event.preventDefault();
  });
  $("a.coming").on("click", function (event) {
    return false;
    event.preventDefault();
  });
  $(".nolink a").on("click", function (event) {
    event.preventDefault();
  });
  $("a.nolink").on("click", function (event) {
    event.preventDefault();
  });
}

function coming() {
  $(".coming a").click(function (event) {
    return false;
    event.preventDefault();
  });
  $(".nolink a").click(function (event) {
    event.preventDefault();
  });
  $("a.nolink").click(function (event) {
    event.preventDefault();
  });
}

function ipad_view() {
  if (
    ua.indexOf("iPhone") > 0 ||
    ua.indexOf("iPod") > 0 ||
    (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
  ) {
    $("head").prepend(
      '<meta name="viewport" content="width=device-width,initial-scale=1">'
    );
  } else if (_ua.Tabvar) {
    $("head").prepend('<meta name="viewport" content="width=1280">');
    $("body").addClass("ipad_view");
  }
}

function sp_menu() {
  $("#spmenu").on("click", function () {
    $(this).toggleClass("active");
    $("#gnav").toggleClass("is-open");
    $("#gnav_pc").toggleClass("is-hopen");
    $("body").toggleClass("fixed");
    $("#logomark").toggleClass("white");
    $("#logomark_normal").toggleClass("white");
  });


   $('.scroll').perfectScrollbar({
       wheelSpeed: 2,
       wheelPropagation: true,
       minScrollbarLength: 20
   });

   $(window).resize(function() {
     $('.scroll').perfectScrollbar('update');
   });


  $("#navi .title").on("click",function () {
  if ($(this).hasClass("dummy")) {

  } else if (!$(this).hasClass("dummy")) {
    $(this).toggleClass("active");
    $(this).next("ul").slideToggle();
    $(this).parent().toggleClass("active");
    $(this).next("ul").toggleClass("active");
  }
  });


  var state = false;

  $("#spmenu").on("click", function () {
    if (state == false) {
      scrollpos = $(window).scrollTop();
      $("body").addClass("fixed").css({ top: -scrollpos });
      $("html, body").prop({ scrollTop: scrollpos });
      state = true;
    } else {
      $("body").removeClass("fixed").css({ top: 0 });
      window.scrollTo(0, scrollpos);

      state = false;
    }
  });
}

function smooth() {
  // #で始まるアンカーをクリックした場合に処理
  $('a[href^="#"].smooth').click(function () {
    var hh = $("header").outerHeight();

    var href = $(this).attr("href");
    // 移動先を取得
    var target = $(href == "#" || href == "" ? "html" : href);
    // 移動先を数値で取得
    if (_ua.Mobile) {
      var position = target.offset().top - 50;
    } else if ($(this).hasClass("smin")) {
      var position = target.offset().top - hh;
    } else {
      var position = target.offset().top;
    }
    gsap.to(window, { duration: 0.5, scrollTo: position, ease:"easeOutexpo" });
    return false;
  });

  var h = $(window).height();
  var hh = $("header").outerHeight();
  var ss = h - hh;
  //$("section#visual").css("height" , ss);
}

function inview2() {
  //スクロール発火処理
  function scrollEffect() {
    $(".inview").each(function () {
      var scroll = $(window).scrollTop(); //現在のyスクロール量を取得
      var windowHeight = $(window).height(); //ウィンドウの高さを取得
      var tgtPos = $(this).offset().top; //ターゲットのy位置を取得
      var subjectHeight = $(this).innerHeight(); //ターゲットの高さを取得
      var threshould; //閾値
      var w = $(window).width();
      threshould = tgtPos - windowHeight - 150; //発火位置調整
      //
      if (scroll > threshould) {
        //クラスを付与する処理
        $(this).addClass("active");
      }
    });
  }
  $(window).scroll(function () {
    scrollEffect();
  });
}

function inview() {
  $(".inview").on("inview", function (event, visible, topOrBottomOrBoth) {
    if (visible) {
      $(this).addClass("active");
    } else {
    }
  });
}

function pagetop() {
  $(window).scroll(function () {
    var pagetop = $("#pagetop");
    if ($(this).scrollTop() > 100) {
      pagetop.addClass("active");
    } else {
      pagetop.removeClass("active");
    }
  });
  $("#pagetop").on("click",function (event) {

    gsap.to(window, { duration: 0.5, scrollTo: 0,ease:"easeOutexpo" });
  });


}

function pagetop() {
  $(window).scroll(function () {
    var pagetop = $("#pagetop");
    if ($(this).scrollTop() > 100) {
      pagetop.addClass("active");
    } else {
      pagetop.removeClass("active");
    }
  });
  $("#pagetop").click(function (event) {
    gsap.to(window, { duration: 0.5, scrollTo: 0,ease:"easeOutexpo"});
  });
  if (_ua.Mobile) {
  $(".report_list h3").on("click",function(){
    $(this).next(".repo").toggleClass("active");
  })
}else{
  
}

}

function ptop_anim() {
  var p = $("#pagetop");
  var t = $("#pagetop .inner");

  TweenMax.set(t, {
    scale: 0,
  });

  p.hover(
    function () {
      TweenMax.to(t, 0.5, {
        scale: 1.3,
        ease: Expo.easeOut,
      });
    },
    function () {
      TweenMax.to(t, 0.3, {
        scale: 0,
        ease: Expo.easeOut,
      });
    }
  );
}




// function cookieConsent() {
//   $('.btn-agree').on('click', function() {
//     $('.cookie-container').removeClass('active');
//     document.cookie("cookieBannerdisplayed");
//   });
//   $('.btn-reject').on('click', function() {
//     $('.cookie-container').removeClass('active');
//   })
//   setTimeout(function(){
//     if(!document.cookie("cookieBannerdisplayed")){
//       $('.cookie-container').addClass('active')
//     }
//   }, 1000)
//   $.cookie("abc", '123')
//   alert($.cookie("abc", '123'))
// }

function  cookieConsent() {
  var cookieBox = document.querySelector(".cookie-container");
  var acceptBtn = cookieBox.querySelector(".btn-agree");
  var rejectBtn = cookieBox.querySelector(".btn-reject");
  var language = document.getElementsByTagName('html')[0].getAttribute('lang');
  var ip = null;
  var mb = false;
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    // true for mobile device
    mb = true;
  }else{
    // false for not mobile device
    mb = false
  }
  var mbm = btoa(mb)
  
  acceptBtn.onclick = function (){
    var d = new Date(); 
    d.setTime(d.getTime() + (365*24*3600*1000));
    //d.setTime(d.getTime() + (5*60*1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = "Cookie=Save Cookie"+";"+expires+"; path=/";
    document.cookie = "lang="+btoa(language)+";"+expires+"; path=/";
    document.cookie = "checkMobile="+mbm+";"+expires+"; path=/";
    $.getJSON('https://api.db-ip.com/v2/free/self', function(data) {
      ip = btoa(data.ipAddress);
      document.cookie = "location="+ip+";"+expires+"; path=/";
    });
   if(document.cookie){
     cookieBox.classList.remove('active')
   }
  }
  rejectBtn.onclick = function (){
    var d = new Date();
    d.setTime(d.getTime() + (365*24*3600*1000));
    //d.setTime(d.getTime() + (5*60*1000));
    var expires = "expires=" + d.toGMTString();
    document.cookie = "Cookie=Cookies are not saved"+";"+expires+"; path=/";
    if(document.cookie){
      cookieBox.classList.remove('active')
    }
  }
  var checkCookie = document.cookie.indexOf("Cookie=Save Cookie");
  var checkCookie2 = document.cookie.indexOf("Cookie=Cookies are not saved");
  if(checkCookie!=-1 || checkCookie2!=-1) {
    cookieBox.classList.remove("active")
  }
  else {
    cookieBox.classList.add("active")
  }
}